import { generateHomePage } from "@/core/database/data/page/home/page-graphql-mutations-operations.js";
import { generateBusinessPage } from "@/core/database/data/page/business/page-graphql-mutations-operations.js";

import { generateEventPage } from "@/core/database/data/page/event/event-graphql-mutations-operations.js";
import { generateLeisureClubPage } from "@/core/database/data/page/leisureclub/leisureclub-graphql-mutations-operations.js";
import { generateMuseumPage } from "@/core/database/data/page/museum/museum-graphql-mutations-operations.js";
import { generateNightLifePage } from "@/core/database/data/page/nightlife/nightlife-graphql-mutations-operations.js";
import { generateParkPage } from "@/core/database/data/page/park/park-graphql-mutations-operations.js";
import { generateRestaurantPage } from "@/core/database/data/page/restaurant/restaurant-graphql-mutations-operations.js";
import { generateShoppingPage } from "@/core/database/data/page/shopping/shopping-graphql-mutations-operations.js";
import { generateHistoryPage } from "@/core/database/data/page/history/history-graphql-mutations-operations.js";
import { generateCulturePage } from "@/core/database/data/page/culture/culture-graphql-mutations-operations.js";
import { generateTourismPage } from "@/core/database/data/page/tourism/tourism-graphql-mutations-operations.js";
import { generateEcommercePage } from "@/core/database/data/page/ecommerce/ecommerce-graphql-mutations-operations.js";
import { generateFaqPage } from "@/core/database/data/page/faq/faq-graphql-mutations-operations.js";

import { generatePageFeature } from "@/core/database/data/page-feature/home/pagefeature-graphql-mutations-operations.js";
import { generatePageFeatureAITools } from "@/core/database/data/page-feature/ai-tools/pagefeature-graphql-mutations-operations.js";

async function generateData() {
  // generateBusinessPage();
  // generatePageFeatureAITools();
  // generateFaqPage();
  // generateHomePage();
  // generatePageFeature();
  // generateEventPage();
  // generateLeisureClubPage();
  // generateMuseumPage();
  // generateNightLifePage();
  // generateParkPage();
  // generateRestaurantPage();
  // generateShoppingPage();
  // generateHistoryPage();
  // generateCulturePage();
  // generateTourismPage();
  // generateEcommercePage();
}

export { generateData };
