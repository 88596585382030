
import { defineComponent, onMounted } from "vue";
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import BeMemberEngage from "@/view/content/widgets/engage/BeMemberEngage.vue";
import FeedWidget from "@/view/content/widgets/feeds/CardBaseService.vue";
import AboutCity from "@/view/content/widgets/stats/Widget1.vue";
import ParkWidget from "@/view/content/widgets/stats/Widget2.vue";
import ExploreCityCategories from "@/view/content/widgets/stats/Widget6.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewsCaroussel from "@/view/content/widgets/stats/Widget8.vue";
import { useRouter } from "vue-router";
import menuModalData from "@/data/menu-modal-collection.json";

import park from "@/data/pages/park_collection.json";
import menuExploreData from "@/data/menu-explore-collection.json";

import { API, Storage, graphqlOperation } from "aws-amplify";
import { listPageTables, listPageFeatureTables } from "@/graphql/queries";

import { generateData } from "@/core/database/data/init/data-graphql-mutations-operations.js";

export default defineComponent({
  name: "poa-home",
  components: {
    HeaderPage,
    ParkWidget,
    AboutCity,
    BeMemberEngage,
    ExploreCityCategories,
    FeedWidget,
    NewsCaroussel,
  },
  setup() {
    const router = useRouter();
    onMounted(() => {
      setCurrentPageBreadcrumbs("Caldeira Cloud", "Bem vindo");
    });

    return {
      park,
      menuExploreData,
      menuModalData,
    };
  },
  mounted() {
    generateData();
    this.loadPage();
    this.loadPageFeaturesBreadcrumbs();
    this.loadPageFeaturesWidget();
    this.loadPageFeaturesNews();
    this.loadPageFeaturesFeed();
  },
  data() {
    return {
      home: {
        banner: "",
        bannerSmall: "",
      },
      breadcrumbs: null,
      news: {
        title: undefined,
        menu: [],
      },
      widget: {
        title: undefined,
        description: undefined,
        metadata: {
          listingTitle: undefined,
          listingSubtitle: undefined,
          buttonLabel: undefined,
          buttonImage: undefined,
        },
      },
      feed: {
        shortDescription: undefined,
        metadata: {
          widget: undefined,
          collection: undefined,
        },
      },
      pageId: "caldeiracloud_home_pagetable",
      pageFeatureBreadcrumbsId: "caldeiracloud_home_breadcrumbs_pagefeature",
      pageFeatureWidgetId: "caldeiracloud_home_widget_pagefeature",
      pageFeatureNewsId: "caldeiracloud_news_pagefeature",
      pageFeatureFeedId: "caldeiracloud_feed_pagefeature",
    };
  },
  methods: {
    async loadPage() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageTables, {
            filter: {
              pageId: {
                eq: this.pageId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageTables.items.length > 0) {
          this.home = response.data.listPageTables.items[0];
          if (this.home) {
            const banner = await Storage.get(this.home.banner, {
              level: "public",
            });
            this.home.banner = banner;

            const bannerSmall = await Storage.get(this.home.bannerSmall, {
              level: "public",
            });
            this.home.bannerSmall = bannerSmall;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async loadPageFeaturesBreadcrumbs() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageFeatureTables, {
            filter: {
              pageId: {
                eq: this.pageFeatureBreadcrumbsId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageFeatureTables.items.length > 0) {
          this.breadcrumbs = response.data.listPageFeatureTables.items[0];
        }
      } catch (error) {
        console.error(error);
      }
    },

    async loadPageFeaturesWidget() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageFeatureTables, {
            filter: {
              pageId: {
                eq: this.pageFeatureWidgetId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageFeatureTables.items.length > 0) {
          this.widget = response.data.listPageFeatureTables.items[0];
          if (this.widget) {
            this.widget.metadata = JSON.parse(
              response.data.listPageFeatureTables.items[0].metadata
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async loadPageFeaturesNews() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageFeatureTables, {
            filter: {
              pageId: {
                eq: this.pageFeatureNewsId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageFeatureTables.items.length > 0) {
          this.news = response.data.listPageFeatureTables.items[0];
          if (this.news) {
            this.news.menu = JSON.parse(
              response.data.listPageFeatureTables.items[0].menu
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async loadPageFeaturesFeed() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageFeatureTables, {
            filter: {
              pageId: {
                eq: this.pageFeatureFeedId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageFeatureTables.items.length > 0) {
          this.feed = response.data.listPageFeatureTables.items[0];
          if (this.feed) {
            let metadata = JSON.parse(
              response.data.listPageFeatureTables.items[0].metadata
            );
            this.feed.metadata = metadata;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
