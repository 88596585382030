
import { defineComponent, toRef, computed, ref } from "vue";
import { Auth } from "aws-amplify";

export default defineComponent({
  name: "BeMemberEngage",
  props: {
    title: String,
    subtitle: String,
    actionBtnLabel: String,
    actionBtnImage: String,
  },
  setup(props) {
    let href = ref("/sejamembro");
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user.username);
        href.value = "/adicionar-negocio";
      })
      .catch((error) => {
        console.log(error);
      });

    const title = toRef(props, "title");
    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitle = toRef(props, "subtitle");
    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const actionBtnLabel = toRef(props, "actionBtnLabel");
    const actionBtnLabelText = computed(() => {
      return actionBtnLabel.value ? actionBtnLabel.value : "Começar";
    });

    const actionBtnImage = toRef(props, "actionBtnImage");
    const actionBtnImageSrc = computed(() => {
      return actionBtnImage.value
        ? actionBtnImage.value
        : "media/icons/duotune/arrows/arr007.svg";
    });

    return {
      href,
      titleText,
      subtitleText,
      actionBtnLabelText,
      actionBtnImageSrc,
    };
  },
});
